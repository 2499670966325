import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

export default function Error404() {
  return (
    <Layout>
      <div className="container mx-auto  px-4 my-12 px-4 lg:px-12">
        <h1 className="font-sans font-bold text-4xl md:text-5xl lg:text-6xl mb-10">
          Page not found
        </h1>
      </div>

      <div className="container mx-auto px-4 mb-24 px-4 lg:px-12 text-3xl">
        <p className="mb-8">404 Error</p>
        <Link
          to="/"
          className={`sm:w-auto border-black font-bold text-lg text-center border-4  rounded-md py-2 px-12 transition duration-500 ease select-none hover:text-white hover:bg-pink-800 focus:outline-none hover:border-pink-800 focus:shadow-outline self-start`}
        >
          Back to Home
        </Link>
      </div>
    </Layout>
  )
}
